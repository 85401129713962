@font-face {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url("/public/fonts/BebasNeue-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("/public/fonts/Montserrat-VariableFont_wght.woff2") format("woff2");
}

  @font-face {
    font-family: "Vogue";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url("/public/fonts/Vogue.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "PeterQuincy";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url("/public/fonts/PeterQuincy.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
      font-family: "Sanford";
      font-style: normal;
      font-weight: normal;
      font-display: swap;
      src: url("/public/fonts/Sanford.woff2") format("woff2");
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
body{
margin: 0;
background: rgb(255, 187, 0);
background: linear-gradient(0deg, #0D0D0D 0%, #F2B279 100%);
}
:root {
    --accent: 136, 58, 234;
    --accent-light: 224, 204, 250;
    --accent-dark: 49, 10, 101;
    --accent-gradient: linear-gradient(
        45deg,
        rgb(var(--accent)),
        rgb(var(--accent-light)) 30%,
        white 60%
    );
}

 p {
  font-family: "Montserrat", "Lucida Console";
  text-transform: var(--accent-light);
  
  
  font-size: 1.4rem;
}
h3{
  font-family: "Vogue";
  letter-spacing: 0.06em;
}
h1{
  font-family: "Vogue";
}
a{
  font-family: "Bebas Neue";
  font-size: 1.1rem;
}
a:hover {
color: #351900;

}

code {
    font-family:
            
        Menlo,
        Monaco,
        Lucida Console,
        Liberation Mono,
        DejaVu Sans Mono,
        Bitstream Vera Sans Mono,
        Courier New,
        monospace;
}


html.dark {
background-color: #000214;
color: #fff;
}

.dark .nav-links a {
color: #fff;
}
.dark .nav-bg {
background-color: #1b1b1b;

}
.dark .hamburger {
filter: invert(100%);
}

.dark .hamburger-close {
filter: invert(100%);
}


.text-title{
color: #6E8C03;
}
.dark .text-title{
color:  #90b800;
}


/*Estilos generales del boton whatsapp*/
.whatsapp-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #25D366;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  animation: breathe 2s ease-in-out infinite;
}

/*Estilos solo al icono whatsapp*/
.whatsapp-btn i {
  color: #fff;
  font-size: 24px;
  animation: beat 2s ease-in-out infinite;
  text-decoration: none;
}

/*Estilos con animation contorno respirando*/
@keyframes breathe {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*Estilos de animacion del icono latiendo*/
@keyframes beat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}



.scroll-down span {
	width: 20px;
	height: 20px;
  display: block;
	border-right: 2px solid #fff;
	border-bottom: 2px solid #fff;
	transform: rotate(45deg);
	margin: -10px;
	animation: animate 2s infinite;
  
}

.scroll-down span:nth-child(2) {
	animation-delay: -0.2s;
}

.scroll-down span:nth-child(3) {
	animation-delay: -0.4s;
}

@keyframes animate {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
		transform: rotate(45deg) translate(10px, 10px);
	}
	100% {
		opacity: 0;
	}
}


